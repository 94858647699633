import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useSuppliersList() {
  // Use toast
  const toast = useToast()

  const refSupplierListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      label: '#', key: 'id', sortable: true,
    },
    {
      label: 'Naam', key: 'name', sortable: true,
    },
    {
      label: 'Omschrijving', key: 'description', sortable: true,
    },
    { label: 'Aanmaakdatum', key: 'createTimestamp', sortable: true },
    {
      label: 'Acties', key: 'actions',
    },
  ]

  const perPage = ref(10)
  const totalSuppliers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(false)
  const showOverlay = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refSupplierListTable.value ? refSupplierListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalSuppliers.value,
    }
  })

  const refetchData = () => {
    refSupplierListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchSuppliers = (ctx, callback) => {
    store
      .dispatch('app-labels-list/fetchSuppliers', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortDesc: isSortDirDesc.value,
        sortBy: sortBy.value,
      })
      .then(response => {
        const { items, _meta } = response.data

        callback(items)
        showOverlay.value = false
        totalSuppliers.value = _meta.totalCount
      })
      .catch(() => {
        showOverlay.value = false
        toast({
          component: ToastificationContent,
          props: {
            title: 'Fout bij ophalen van lijst met leveranciers',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchSuppliers,
    tableColumns,
    perPage,
    currentPage,
    totalSuppliers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    refSupplierListTable,
    refetchData,
    isSortDirDesc,
    showOverlay,
  }
}
