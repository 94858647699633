<template>
  <b-card>
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        class="mb-2"
        @submit.prevent="handleSubmit(onSubmit)"
      >
        <b-row>
          <b-col
            cols="12"
            class="mb-2"
          >
            <b-media class="mb-0">
              <template #aside>
                <b-overlay
                  :show="showUploadLoader"
                  rounded="sm"
                >
                  <b-avatar
                    ref="previewEl"
                    :src="supplierData.logoFileURL"
                    :text="supplierData.name"
                    size="90px"
                    square
                    variant=""
                  />
                </b-overlay>
              </template>
              <div class="d-flex flex-wrap">
                <b-button
                  variant="primary"
                  @click="$refs.refInputEl.click()"
                >
                  <input
                    ref="refInputEl"
                    type="file"
                    class="d-none"
                    @input="uploadImage"
                  >
                  <span class="d-none d-sm-inline">Uploaden</span>
                  <feather-icon
                    icon="EditIcon"
                    class="d-inline d-sm-none"
                  />
                </b-button>
              </div>
              <b-card-text class="text-muted">
                <small>Toegestaan JPG, GIF of PNG. Maximale grootte van 2MB.<br>
                  De wijziging van je avatar zal pas bij de volgende login van toepassing zijn.</small>
              </b-card-text>
            </b-media>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="ID"
              label-for="id"
            >
              <b-form-input
                id="id"
                v-model="supplierData.id"
                readonly="readonly"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <validation-provider
              #default="validationContext"
              name="name"
              rules="required"
            >
              <b-form-group
                label="Naam"
                label-for="name"
              >
                <b-form-input
                  id="name"
                  v-model="supplierData.name"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- Field: Created date -->
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="Aanmaakdatum"
              label-for="createTimestamp"
            >
              <b-form-input
                id="createTimestamp"
                v-model="supplierData.createTimestamp"
                type="text"
                readonly="readonly"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="6"
          >
            <validation-provider
              #default="validationContext"
              name="ecedoLabelUUID"
              rules="required"
            >
              <b-form-group
                label="UUID"
                label-for="ecedoLabelUUID"
              >
                <b-form-input
                  id="ecedoLabelUUID"
                  v-model="supplierData.ecedoLabelUUID"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <validation-provider
              #default="validationContext"
              name="colorHexCode"
              rules="required"
            >
              <b-form-group
                label="Kleurcode"
                label-for="colorHexCode"
              >
                <b-form-input
                  id="colorHexCode"
                  v-model="supplierData.colorHexCode"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="#000000"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <validation-provider
              #default="validationContext"
              name="colorHexCodeSecondary"
              rules="required"
            >
              <b-form-group
                label="Secundair Kleurcode"
                label-for="colorHexCodeSecondary"
              >
                <b-form-input
                  id="colorHexCodeSecondary"
                  v-model="supplierData.colorHexCodeSecondary"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="#ffffff"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
              cols="12"
              md="6"
          >
            <validation-provider
                #default="validationContext"
                name="ecedoElectricityPVEAN"
                rules=""
            >
              <b-form-group
                  label="Elektriciteit PV"
                  label-for="ecedoElectricityPVEAN "
              >
                <b-form-input
                    id="ecedoElectricityPVEAN"
                    v-model="supplierData.ecedoElectricityPVEAN "
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
              cols="12"
              md="6"
          >
            <validation-provider
                #default="validationContext"
                name="ecedoGasPVEAN"
                rules=""
            >
              <b-form-group
                  label="Gas PV"
                  label-for="ecedoGasPVEAN "
              >
                <b-form-input
                    id="ecedoGasPVEAN"
                    v-model="supplierData.ecedoGasPVEAN "
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="12"
          >
            <b-form-group
              label="Beschrijving"
              label-for="description"
            >
              <b-form-textarea
                id="description"
                v-model="supplierData.description"
                rows="3"
                no-resize
                maxlength="100"
                trim
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="5"
          >
            <validation-provider
              #default="validationContext"
              name="postalCode"
              rules="required|postalcode"
            >
              <b-form-group
                label="Postcode"
                label-for="postalCode"
              >
                <b-form-input
                  id="postalCode"
                  v-model="supplierData.postalCode"
                  v-debounce:500ms="getAddress"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <validation-provider
              #default="validationContext"
              name="houseNumber"
              rules="required|integer"
            >
              <b-form-group
                label="Huisnummer"
                label-for="houseNumber"
              >
                <b-form-input
                  id="houseNumber"
                  v-model="supplierData.houseNumber"
                  v-debounce:500ms="getAddress"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="houseNumberAddition"
              rules=""
            >
              <b-form-group
                label="Huisnummertoevoeging"
                label-for="houseNumberAddition"
              >
                <b-form-input
                  id="houseNumberAddition"
                  v-model="supplierData.houseNumberAddition"
                  v-debounce:500ms="getAddress"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <validation-provider
              #default="validationContext"
              name="street"
              rules="required"
            >
              <b-form-group
                label="Straat"
                label-for="street"
              >
                <b-form-input
                  id="street"
                  v-model="supplierData.street"
                  :state="getValidationState(validationContext)"
                  :disabled="true"
                />
                <b-spinner
                  v-if="showAddressSpinner"
                  style="position:absolute; top:26px;right:20px"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <validation-provider
              #default="validationContext"
              name="city"
              rules="required"
            >
              <b-form-group
                label="Plaats"
                label-for="city"
              >
                <b-form-input
                  id="city"
                  v-model="supplierData.city"
                  :state="getValidationState(validationContext)"
                  :disabled="true"
                />
                <b-spinner
                  v-if="showAddressSpinner"
                  style="position:absolute; top:26px;right:20px"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <validation-provider
              #default="validationContext"
              name="email"
              rules="required|email"
            >
              <b-form-group
                label="E-mailadres"
                label-for="email"
              >
                <b-form-input
                  id="email"
                  v-model="supplierData.senderEmail"
                  type="text"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <validation-provider
              #default="validationContext"
              name="phoneNumber"
              rules="required|phoneNumber"
            >
              <b-form-group
                label="Telefoonnummer"
                label-for="phoneNumber"
              >
                <b-form-input
                  id="phoneNumber"
                  v-model="supplierData.phoneNumber"
                  type="text"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            <b-button
              variant="secondary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              type="button"
              :to="{ name: 'app-labels-list' }"
            >
              terug
            </b-button>
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              type="submit"
            >
              Opslaan
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BFormInvalidFeedback,
  BCard,
  BSpinner,
  BCardText,
  BAvatar,
  BMedia,
  BOverlay, BFormTextarea,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import supplierStoreModule from '@/views/pages/supplier/supplierStoreModule'
import { getDirective } from 'vue-debounce'
import {
  alphaNum,
  email,
  required,
  postalcode,
  phoneNumber,
} from '@core/utils/validations/validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import useSuppliersList from '@/views/pages/supplier/useSuppliersList'
import Ripple from 'vue-ripple-directive'
import errorAndSuccesDefaultMessage from '@core/app-config/errorAndSuccesDefaultMessage'

export default {
  directives: {
    Ripple,
    debounce: getDirective(),
  },
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    BCard,
    BSpinner,
    BCardText,
    BAvatar,
    BMedia,
    BOverlay,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      postalcode,
      phoneNumber,
      supplierData: {
        logoFileURL: null,
        id: null,
        name: '',
        description: '',
        type: '',
        postalCode: null,
        houseNumber: null,
        houseNumberAddition: null,
        ecedoGasPVEAN: null,
        ecedoElectricityPVEAN: null,
      },
      showAddressSpinner: false,
      showUploadLoader: false,
    }
  },
  beforeMount() {
    store.dispatch('app-labels-edit/fetchSupplier', { id: router.currentRoute.params.id })
      .then(response => {
        this.supplierData = response.data
      })
      .catch(() => {
        this.supplierData = undefined
      })
  },
  methods: {
    getAddress() {
      const somethingChanged = []

      if (!this.supplierData.postalCode || !this.supplierData.houseNumber) {
        return
      }

      if (this.supplierData.postalCode) {
        somethingChanged.push(true)
      }

      if (this.supplierData.houseNumber) {
        somethingChanged.push(true)
      }

      if (this.supplierData.houseNumberAddition) {
        somethingChanged.push(true)
      }

      if (somethingChanged.includes(true)) {
        this.showAddressSpinner = true
        this.supplierData.street = null
        this.supplierData.city = null
        this.supplierData.province = null
        store.dispatch('app-labels-edit/fetchCorrespondenceAddress', this.supplierData)
          .then(response => {
            this.showAddressSpinner = false
            this.supplierData.street = response.data.street
            this.supplierData.city = response.data.city
            this.supplierData.province = response.data.province
            this.showAddressSpinner = false
          })
          .catch(() => {
            this.showAddressSpinner = false
          })
      }
    },
    uploadImage() {
      const file = document.querySelector('input[type=file]').files[0]
      if (file.size > 10485760) {
        this.showUploadLoader = false
        this.$swal({
          icon: 'error',
          title: 'Afbeeldingsgrootte',
          text: 'De maximale afbeeldinggrootte is 10mb. Het huidige bestand is te groot.',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        })
      } else {
        this.showUploadLoader = true
        const reader = new FileReader()
        reader.addEventListener(
          'load',
          () => {
            const fileUploadData = {
              file: reader.result,
              relationModel: 'supplier',
              name: file.name,
              supplierID: this.supplierData.id,
            }

            store.dispatch('app-labels-edit/uploadImage', fileUploadData)
              .then(response => {
                store.dispatch('app-labels-edit/patchSupplier', { id: this.supplierData.id, logoFileID: response.data.fileID })
                  .then(res => {
                    this.supplierData = res.data
                    this.showUploadLoader = false
                    this.$swal({
                      icon: 'success',
                      title: 'Upload',
                      text: 'Upload van de foto is gelukt!',
                      customClass: {
                        confirmButton: 'btn btn-success',
                      },
                    })
                  })
                  .catch(() => {
                    this.showUploadLoader = false
                    this.$swal({
                      icon: 'error',
                      title: 'Uploaden',
                      text: `${errorAndSuccesDefaultMessage.uploadDataError}`,
                      customClass: {
                        confirmButton: 'btn btn-danger',
                      },
                    })
                  })
              })
              .catch(() => {
                this.showUploadLoader = false
                this.$swal({
                  icon: 'error',
                  title: 'Uploaden',
                  text: `${errorAndSuccesDefaultMessage.uploadDataError}`,
                  customClass: {
                    confirmButton: 'btn btn-danger',
                  },
                })
              })
          },
          false,
        )

        reader.readAsDataURL(file)
      }
    },
    onSubmit() {
      store.dispatch('app-labels-edit/updateSupplier', this.supplierData)
        .then(() => {
          router.push({ name: 'app-labels-list' })
        })
    },
  },
  setup() {
    const SUPPLIERS_EDIT_APP_STORE_MODULE_NAME = 'app-labels-edit'

    // Register module
    if (!store.hasModule(SUPPLIERS_EDIT_APP_STORE_MODULE_NAME)) store.registerModule(SUPPLIERS_EDIT_APP_STORE_MODULE_NAME, supplierStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SUPPLIERS_EDIT_APP_STORE_MODULE_NAME)) store.unregisterModule(SUPPLIERS_EDIT_APP_STORE_MODULE_NAME)
    })

    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    const {
      statusOptions,
      countryOptions,
      districtOptions,
      resolveDistrictForCountry,
      externalFinanceTypeOptions,
    } = useSuppliersList()

    return {
      refFormObserver,
      getValidationState,
      statusOptions,
      countryOptions,
      districtOptions,
      resolveDistrictForCountry,
      externalFinanceTypeOptions,
    }
  },
}
</script>

<style>

</style>
