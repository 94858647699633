import axios from '@axios'
import axiosDefaultConfig from '@core/app-config/axiosDefaultConfig'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchSuppliers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${axiosDefaultConfig.backendEndPoint}suppliers`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSupplier(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${axiosDefaultConfig.backendEndPoint}suppliers/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addSupplier(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${axiosDefaultConfig.backendEndPoint}suppliers`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateSupplier(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`${axiosDefaultConfig.backendEndPoint}suppliers/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    patchSupplier(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`${axiosDefaultConfig.backendEndPoint}suppliers/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    uploadImage(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${axiosDefaultConfig.backendEndPoint}file/upload`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCorrespondenceAddress(ctx, data) {
      let url = ''
      if (data.houseNumberAddition) {
        url = `${axiosDefaultConfig.backendEndPoint}search/address?postalCode=${data.postalCode}&houseNumber=${data.houseNumber}&houseNumberAddition=${data.houseNumberAddition}`
      } else {
        url = `${axiosDefaultConfig.backendEndPoint}search/address?postalCode=${data.postalCode}&houseNumber=${data.houseNumber}`
      }
      return new Promise((resolve, reject) => {
        axios
          .get(url)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
